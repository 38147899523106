import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService'

export class PriceAction extends Component {
    static displayName = PriceAction.name;

    constructor(props) {
        super(props);
        this.state = { prices: [], exchanges: [], loading: true };
    }

    componentDidMount() {
        this.populatePriceData();
        this.populateExchangeData();
    }

    static renderPriceActionTable(
        exchange,
        prices,
        beforeAndAddAvg,
        addAnd24HrAvg,
        addAnd48HrAvg,
        addAnd72HrAvg,
        addAnd96HrAvg,
        addAnd120HrAvg,
    ) {
        return (
            <div key={"div"+exchange.id}>
                <table className='table table-striped table-hover' labelledby="tabelLabel">
                    <caption><h2>{exchange.name}</h2></caption>
                    <thead>
                        <tr key={exchange.id}>
                            <th>Symbol</th>
                            <th>24 Hrs before</th>
                            <th></th>
                            <th>Price when listed</th>
                            <th></th>
                            <th>24 Hrs</th>
                            <th></th>
                            <th>48 Hrs</th>
                            <th></th>
                            <th>72 Hrs</th>
                            <th></th>
                            <th>96 Hrs</th>
                            <th></th>
                            <th>120 Hrs</th>
                        </tr>
                    </thead>
                    <tbody>
                        {prices.map(price =>
                            <tr key={price.id}>
                                <td>{price.symbol}</td>
                                <td>${price.price24HoursBefore}</td>
                                <td style={{ color: price.priceDiff24BeforeAndAdded > 0 ? "green" : "red" }}>{price.priceDiff24BeforeAndAdded}%</td>
                                <td>${price.priceWhenAdded}</td>
                                <td style={{ color: price.priceDiffAddedAnd24After > 0 ? "green" : "red" }}>{price.price24AfterHours !== 0 && price.price24AfterHours !== null ? price.priceDiffAddedAnd24After + "%" : ""}</td>
                                <td>{price.price24AfterHours !== 0 && price.price24AfterHours !== null ? "$" + price.price24AfterHours : ""}</td>
                                <td style={{ color: price.priceDiffAddedAnd48After > 0 ? "green" : "red" }}>{price.price48AfterHours !== 0 && price.price48AfterHours !== null ? price.priceDiffAddedAnd48After + "%" : ""}</td>
                                <td>{price.price48AfterHours !== 0 && price.price48AfterHours !== null ? "$" + price.price48AfterHours : ""}</td>
                                <td style={{ color: price.priceDiffAddedAnd72After > 0 ? "green" : "red" }}>{price.price72AfterHours !== 0 && price.price72AfterHours !== null ? price.priceDiffAddedAnd72After + "%" : ""}</td>
                                <td>{price.price72AfterHours !== 0 && price.price72AfterHours !== null ? "$" + price.price72AfterHours : ""}</td>
                                <td style={{ color: price.priceDiffAddedAnd96After > 0 ? "green" : "red" }}>{price.price96AfterHours !== 0 && price.price96AfterHours !== null ? price.priceDiffAddedAnd96After + "%" : ""}</td>
                                <td>{price.price96AfterHours !== 0 && price.price96AfterHours !== null ? "$" + price.price96AfterHours : ""}</td>
                                <td style={{ color: price.priceDiffAddedAnd120After > 0 ? "green" : "red" }}>{price.price120AfterHours !== 0 && price.price120AfterHours !== null ? price.priceDiffAddedAnd120After + "%" : ""}</td>
                                <td>{price.price120AfterHours !== 0 && price.price120AfterHours !== null ? "$" + price.price120AfterHours : ""}</td>
                            </tr>
                        )}

                    </tbody>
                    <tfoot>
                        <tr key={exchange.name}>
                            <td><b>Average:</b></td>
                            <td></td>
                            <td style={{ color: beforeAndAddAvg > 0 ? "green" : "red" }}>{isNaN(beforeAndAddAvg) ? "" : Math.round((beforeAndAddAvg + Number.EPSILON) * 100) / 100 + "%"}</td>
                            <td></td>
                            <td style={{ color: addAnd24HrAvg > 0 ? "green" : "red" }}>{isNaN(addAnd24HrAvg) ? "" : Math.round((addAnd24HrAvg + Number.EPSILON) * 100) / 100 + "%"}</td>
                            <td></td>
                            <td style={{ color: addAnd48HrAvg > 0 ? "green" : "red" }}>{isNaN(addAnd48HrAvg) ? "" : Math.round((addAnd48HrAvg + Number.EPSILON) * 100) / 100 + "%"}</td>
                            <td></td>
                            <td style={{ color: addAnd72HrAvg > 0 ? "green" : "red" }}>{isNaN(addAnd72HrAvg) ? "" : Math.round((addAnd72HrAvg + Number.EPSILON) * 100) / 100 + "%"}</td>
                            <td></td>
                            <td style={{ color: addAnd96HrAvg > 0 ? "green" : "red" }}>{isNaN(addAnd96HrAvg) ? "" : Math.round((addAnd96HrAvg + Number.EPSILON) * 100) / 100 + "%"}</td>
                            <td></td>
                            <td style={{ color: addAnd120HrAvg > 0 ? "green" : "red" }}>{isNaN(addAnd120HrAvg) ? "" : Math.round((addAnd120HrAvg + Number.EPSILON) * 100) / 100 + "%"}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        );
    }

    render() {

        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            :
            this.state.exchanges
                .filter(ex => ex.id !== 1)
                .map(exchange =>
                    PriceAction.renderPriceActionTable(
                        exchange,
                        this.state.prices.filter(p => p.exchangeId === exchange.id),
                        (this.state.prices.filter(p => p.exchangeId === exchange.id && p.priceDiff24BeforeAndAdded !== 0).reduce((totalPrice, price) => totalPrice + price.priceDiff24BeforeAndAdded, 0) / this.state.prices.filter(p => p.exchangeId === exchange.id && p.priceDiff24BeforeAndAdded !== 0).length),
                        (this.state.prices.filter(p => p.exchangeId === exchange.id && p.priceDiffAddedAnd24After !== 0).reduce((totalPrice, price) => totalPrice + price.priceDiffAddedAnd24After, 0) / this.state.prices.filter(p => p.exchangeId === exchange.id && p.priceDiffAddedAnd24After !== 0).length),
                        (this.state.prices.filter(p => p.exchangeId === exchange.id && p.priceDiffAddedAnd48After !== 0).reduce((totalPrice, price) => totalPrice + price.priceDiffAddedAnd48After, 0) / this.state.prices.filter(p => p.exchangeId === exchange.id && p.priceDiffAddedAnd48After !== 0).length),
                        (this.state.prices.filter(p => p.exchangeId === exchange.id && p.priceDiffAddedAnd72After !== 0).reduce((totalPrice, price) => totalPrice + price.priceDiffAddedAnd72After, 0) / this.state.prices.filter(p => p.exchangeId === exchange.id && p.priceDiffAddedAnd72After !== 0).length),
                        (this.state.prices.filter(p => p.exchangeId === exchange.id && p.priceDiffAddedAnd96After !== 0).reduce((totalPrice, price) => totalPrice + price.priceDiffAddedAnd96After, 0) / this.state.prices.filter(p => p.exchangeId === exchange.id && p.priceDiffAddedAnd96After !== 0).length),
                        (this.state.prices.filter(p => p.exchangeId === exchange.id && p.priceDiffAddedAnd120After !== 0).reduce((totalPrice, price) => totalPrice + price.priceDiffAddedAnd120After, 0) / this.state.prices.filter(p => p.exchangeId === exchange.id && p.priceDiffAddedAnd120After !== 0).length)
                    )
                );

        return (
            <div>
                {/*<h1 id="tabelLabel">Price Action</h1>*/}
                {contents}
            </div>
        );
    }

    async populatePriceData() {
        const token = await authService.getAccessToken();
        const response = await fetch('priceaction', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        this.setState({ prices: data, loading: false });
    }

    async populateExchangeData() {
        const token = await authService.getAccessToken();
        const response = await fetch('exchange', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        this.setState({ exchanges: data, loading: false });
    }
}
